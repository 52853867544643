export const Current_Month_Layer_Title = [
	//
	{title: "중대재해처벌법 - Q&A", key:"punishment_qna"},
	{title: "작업 전 스트레칭", key:"before_stretching"},
	{title: "안전보건표지", key:"safety_sign"},
	{title: "50인미만 안전보건관리체계", key:"less_than_50"},
	{title: "지게차충돌", key:"forklift_collision"},

	{title: "외국어 - 출입금지", key:"no_allowed"},
	{title: "소음작업", key:"noise_work"},
	//
	{title: "그림으로 이해하는 포스터(지게차)", key:"forklift_picture"},
	{title: "위험성 평가", key:"risk_assessment"},
	//
	{title: "중대재해처벌법-질문", key:"punishment_answer"},

	{title: "국민행동요령", key:"action_guidelines"},
	{title: "산업재해발생", key:"industrial_accident"},
	//
	{title: "그림으로 이해하는 포스터(안전한 현장)", key:"workplace_picture"},
	{title: "컨베이어벨트", key:"conveyor_belt"},
	{title: "외국어 - 화기금지", key:"no_fire"},

	{title: "재해사례 - 지게차", key:"disaster_forklift"},
	{title: "안전수칙퀴즈", key:"safety_quiz"},
	{title: "화재폭발예방설비 긴급 재정지원", key:"financial_assistance"},
	{title: "외국어 - 회전말림", key:"rotational_rolling"},
	{title: "자동차 부딫힘", key:"car_crash"},

	{title: "구조물 행동요령", key:"structure_guidelines"},
	{title: "작업 전 안전점검", key:"before_check"},
	{title: "끼임사고", key:"entrapment_accident"},
	{title: "작업장 이동통로", key:"workplace_movement"},
	{title: "외국어 - 적재물 무너짐 주의", key:"collapsing_cargo"},

	{title: "비상상황 3원칙", key:"three_principles"},
	{title: "화학물질 누출 국민행동요령", key:"chemical_leak"},
	{title: "사례로 알아보는 안전한 현장 만들기", key:"safe_workplace"},
	{title: "창고시설 화재안전성능기준", key:"fire_safety"},
	{title: "사례 - 깔림사고", key:"coverage_accident"},

	{title: "중대재해처벌법-퀴즈", key:"punishment_quiz"},
	{title: "사망사고 안전조치", key:"fatal_accidents"},
	{title: "LOTO", key:"lo_to"},
	{title: "기계기구 방호조치 실시", key:"protective_measures"},
	{title: "화재위험", key:"fire_hazard"},

	{title: "사례로 알아보는 안전한 현장 만들기2", key:"safe_workplace2"},
]



// export const Current_Month_Layer_Title = [
// 	{title: "프레스 산업군 안전사고\n 사례", key:"safety_accident_cases"},
// 	{title: "프레스 설치,사용 및 \n정비작업 점검", key:"inspection_of_press"},
// 	{title: "프레스 산업군 법령시행", key:"laws_on_Press"},
// 	{title: "중대재해 알아보기", key:"learn_major_disaster"},
// 	{title: "시의적이슈 온열질환", key:"heat_illness"},
//
// 	{title: "매월 이슈 콘텐츠", key:"issue_content"},
// 	{title: "외국인근로자 재해예방표지\n 2인1조", key:"foreign_group"},
// 	{title: "그림으로 이해하는 안전보건", key:"safety_picture"},
// 	{title: "사례로 알아보는 안전한 \n현장만들기", key:"safe_place_example"},
// 	{title: "안전교육 지게차", key:"safe_fork_lift"},
//
// 	{title: "프레스산업군 \n산업안전보건법 35조", key:"article_thirty_five"},
// 	{title: "중대재해처벌법 홍보 \n콘텐츠 (Q&A)", key:"punishment_qna"},
// 	{title: "시의적이슈 코로나19 \n재확산방지", key:"corona_19"},
// 	{title: "밀폐공간작업 안전수칙", key:"confined_space"},
// 	{title: "외국인근로자 재해예방표지 \n폭발성물질", key:"foreign_explosive"},
//
// 	{title: "그림으로 이해하는 포스터 \n(끼임)", key:"stuck_picture"},
// 	{title: "감전재해 예방 3대 \n기본 수칙", key:"preventing_electric_shock"},
// 	{title: "당신이 중대재해에 대해 \n모르는 5가지", key:"disaster_five_things"},
// 	{title: "안전교육 사다리", key:"safe_ladder"},
// 	{title: "프레스 산업군 안전사고", key:"press_safety_accident"},
//
// 	{title: "안전인증이란?", key:"safety_certification"},
// 	{title: "중대재해처벌법 홍보", key:"punishment_promotion"},
// 	{title: "프레스 작업 안전수칙 퀴즈", key:"work_safety_quiz"},
// 	{title: "외국인근로자 재해예방표지 \n추락위험", key:"foreign_falling"},
// ]
